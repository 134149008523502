// DO NOT CHANGE SEQUENCE OF AFFILIATIONS, there are usage of index in some places
export const affiliations = {
  hospitals: [
    "all-hosp-groups",
    "gurukul",
    "sindhubhavan",
    "racecourse",
    "bhayli",
    "rajkot",
    "gandhidham",
  ],
  defaultHospital: "all-hosp-groups",
} as const

export type AffiliationConfig = {
  name: string
  latlng: {
    lat: number
    lng: number
  }
  tel: string
  why: string
  mapLink: string
  featuredDoctors: Set<string>
  title: string
  description: string
  canonical: string
  city?: string
  address?: string
}
export const affiliationMap: Map<Affiliation, AffiliationConfig> = new Map([
  [
    "all-hosp-groups",
    {
      name: "All",
      title: "Best Multispeciality Hospital in Gujarat | Sterling Hospitals",
      canonical: "https://www.sterlinghospitals.com",
      description: "Sterling Hospitals is a leading multispecialty Hospital chain in Gujarat, offering advanced medical care, qualified professionals, and personalized patient care",
      latlng: { lat: 23.04888, lng: 72.531285 },
      tel: "09898987878",
      mapLink: "https://maps.app.goo.gl/Q2oAFmg53QvydeF26",
      why: "Choose Sterling Hospitals, Gujarat's first NABH-accredited private hospital with over 20 years of excellence in patient care. Our 6 hospitals across the state offer comprehensive, tailored healthcare, touching lakhs of lives annually. Conveniently located and managed by compassionate professionals, Sterling Hospitals ensures accessible, top-tier care for every patient.",
      featuredDoctors: new Set([
        "dr-patel-pooja-nandwani",
        "dr-sarveshwer-prasad",
        "dr-gaurang-vaghani",
        "dr-harish-vaja",
        "dr-ashish-susvirkar",
        "dr-gaurav-goel",
        "dr-manoj-r-mehta",
        "dr-vibha-naik",
        "dr-rushikumar-panchal",
        "dr-sudhir-shah",
        "dr-pratibha-dileep",
        "dr.-chandra-somesh-suresh",
      ]),
    },
  ],
  [
    "gurukul",
    {
      name: "Ahmedabad - Gurukul",
      city: "Ahmedabad",
      address: "Sterling Hospitals, Gurukul: Memnagar, Ahmedabad, Gujarat - 380052",
      title: "Best Hospital in Ahmedabad | Sterling Hospitals, Ahmedabad - Gurukul",
      description: "Explore Sterling Hospitals, Ahmedabad - Gurukul. Gujarat's premier healthcare destination. Offering exceptional medical services and superior facilities, discover why we're the best hospital.",
      canonical: "https://www.sterlinghospitals.com/gurukul",
      latlng: { lat: 23.04888, lng: 72.531285 },
      tel: "079-40011933",
      mapLink: "https://maps.app.goo.gl/Q2oAFmg53QvydeF26",
      why: "Choose Sterling Hospitals, Gurukul, Ahmedabad, one of Gujarat's premier multi-speciality hospitals. Accredited by NABH, we offer comprehensive, personalized care, impacting lakhs of lives annually. Conveniently located and managed by compassionate professionals, we ensure top-tier healthcare and accessibility for everyone.",
      featuredDoctors: new Set([
        "dr-sudhir-shah",
        "dr-pratibha-dileep",
        "dr-rasesh-a-pothiwala",
        "dr-abhishek-sharma",
        "dr-sonal-dalal",
        "dr-nitin-singhal",
        "dr-pravin-nandwana",
        "dr-himanshu-mathur",
        "dr-harsh-v-desai",
      ]),
    },
  ],
  [
    "sindhubhavan",
    {
      name: "Ahmedabad - Sindhu Bhavan",
      city: "Ahmedabad",
      address: "Sterling Hospitals, Sindhu Bhavan: Bodakdev, Ahmedabad, Gujarat - 380054",
      title: "Best Cancer Hospital in Ahmedabad | Sterling Hospitals, Ahmedabad - Sindhu Bhavan",
      description: "Explore Sterling Hospitals, Ahmedabad - Sindhu Bhavan. Gujarat's premier healthcare destination. Offering exceptional medical services and superior facilities, discover why we're the best hospital.",
      canonical: "https://www.sterlinghospitals.com/sindhubhavan",
      latlng: { lat: 23.041494, lng: 72.509445 },
      tel: "079-40011933",
      mapLink: "https://maps.app.goo.gl/kMSL1ndWDcqeN51h6",
      why: "Choose Sterling Hospitals, Sindhu Bhavan, a top multi-speciality hospital in Gujarat. NABH-accredited, we deliver personalized, comprehensive care, impacting lakhs of lives each year. Conveniently located and run by compassionate professionals, we provide exceptional healthcare and easy accessibility for all.",
      featuredDoctors: new Set([
        "dr.-chandra-somesh-suresh",
        "dr-patel-pooja-nandwani",
        "dr-shah-hoc-bhavin-ashokbhai",
        "dr-harsh-v-desai",
        "dr-ankit-vaishnav",
        "dr-shukla-ketan-d",
        "dr-patel-anand-chandulal",
        "dr-indranil-sinha",
        "dr-harsh-v-desai",
      ]),
    },
  ],
  [
    "racecourse",
    {
      name: "Vadodara - Race Course Road",
      city: "Vadodara",
      address: "Sterling Hospitals, Racecourse Road: Opp. to Inox Cinema, Hari Nagar, Circle West, Vadodara, Gujarat - 390007",
      title: "Best Hospital in Vadodara | Sterling Hospitals, Vadodara - Race Course Road",
      description: "Explore Sterling Hospitals, Vadodara - Race Course Road. Gujarat's premier healthcare destination. Offering exceptional medical services and superior facilities, discover why we're the best hospital.",
      canonical: "https://www.sterlinghospitals.com/racecourse",
      latlng: { lat: 22.312662, lng: 73.158858 },
      tel: "9924449972",
      mapLink: "https://maps.app.goo.gl/8smBm1aAZHAi51rG8",
      why: "Choose Sterling Hospitals, Vadodara, one of Gujarat's premier multi-speciality medical facilities. Accredited by NABH, we offer tailored, all-encompassing healthcare, positively influencing countless lives annually. Situated conveniently and managed by empathetic experts, we deliver outstanding medical services with convenient access for everyone.",
      featuredDoctors: new Set([
        "dr-gaurav-goel",
        "dr-manoj-r-mehta",
        "dr-madhav-tamhankar",
        "dr-maulik-vaja",
        "dr-archana-dwivedi",
        "dr-devendra-talera",
        "dr-ankur-masani",
        "dr-keyur-buch",
        "dr-vaibhav-atrekar",
        "dr-vibha-naik",
        "dr-amit-dave",
        "dr-revathi-aiyer",
      ]),
    },
  ],
  [
    "bhayli",
    {
      name: "Vadodara - Bhayli",
      city: "Vadodara",
      address: "Sterling Hospitals, Bhayli: Behind Waves Club, Bhayli, Vadodara, Gujarat - 391410",
      title: "Best Cancer Hospital in Vadodara | Sterling Hospitals, Bhayli",
      description: "Explore Sterling Hospitals, Bhayli. Gujarat's premier healthcare destination. Offering exceptional medical services and superior healthcare facilities, discover why we're the best hospital.",
      canonical: "https://www.sterlinghospitals.com/bhayli",
      latlng: { lat: 22.285151, lng: 73.129754 },
      tel: "9081000557",
      mapLink: "https://maps.app.goo.gl/svLEiuRuSGc78UNS7",
      why: "Discover Sterling Hospitals, located in Bhayli, Vadodara, renowned as a leading multi-speciality hospital in Gujarat. With NABH accreditation, we are dedicated to providing individualized, comprehensive care, positively impacting numerous lives each year. Our facility, staffed by compassionate professionals, ensures exceptional healthcare services with convenient accessibility for all.",
      featuredDoctors: new Set([
        "dr-vibha-naik",
        "dr-pradeep-r-shah",
        "dr-rushikumar-panchal",
        "dr-inderpreet-kaur",
        "dr-devang-patel",
        "dr-raghunandan-g-c",
        "dr-saptarshi-banerjee",
        "dr-saket-kumar",
        "dr-naresh-shah",
        "dr-rajeev-kumar",
      ]),
    },
  ],
  [
    "rajkot",
    {
      name: "Rajkot",
      city: "Rajkot",
      address: "Sterling Hospitals, Rajkot: 150 Feet Ring Rd, Raiya Circle, Rajkot, Gujarat - 360007",
      title: "Best Hospital in Rajkot | Sterling Hospitals, Rajkot",
      description: "Explore Sterling Hospitals, Rajkot. Gujarat's premier healthcare destination. Offering exceptional medical services and superior facilities, discover why we're the best hospital.",
      canonical: "https://www.sterlinghospitals.com/rajkot",
      latlng: { lat: 22.306737, lng: 70.767542 },
      tel: "0281-6785777",
      mapLink: "https://maps.app.goo.gl/EnWbtq7BJHur8JY99",
      why: "Explore Sterling Hospitals in Rajkot, recognized as a premier multi-speciality hospital in Gujarat. With NABH accreditation, we are committed to delivering personalized, holistic care, positively influencing countless lives annually. Our facility, staffed by caring professionals, guarantees exceptional healthcare services with convenient accessibility for everyone.",
      featuredDoctors: new Set([
        "dr-mridul-sharma",
        "dr-ravi-bhojani",
        "dr-sarveshwer-prasad",
        "dr-gaurang-vaghani",
        "dr-rupesh-mehta",
        "dr-nishith-vyas",
        "dr-sarika-patil",
        "dr-mayur-vaghela",
        "dr-kaushik-kotak",
        "dr-pritesh-lohar",
      ]),
    },
  ],
  [
    "gandhidham",
    {
      name: "Gandhidham",
      city: "Gandhidham",
      address: "Sterling Ramkrishna Speciality Hospital, Gandhidham: Nr. YMCA, Opp. to IFFCO Colony, Gandhidham, Gujarat - 370201",
      title: "Best Hospital in Gandhidham | Sterling Hospitals, Gandhidham",
      description: "Explore Sterling Hospitals, Gandhidham. Gujarat's premier healthcare destination. Offering exceptional medical services and superior facilities, discover why we're the best hospital.",
      canonical: "https://www.sterlinghospitals.com/gandhidham",
      latlng: { lat: 23.069198, lng: 70.122181 },
      tel: "9574006377",
      mapLink: "https://maps.app.goo.gl/LjE5i1Zrkn25HwdH6",
      why: "Discover Sterling Hospitals in Gandhidham, renowned as Gujarat's top multi-speciality medical center. With NABH accreditation, we pledge to provide tailored, comprehensive care, positively impacting numerous lives each year. Our compassionate professionals ensure exceptional healthcare services with easy accessibility for all.",
      featuredDoctors: new Set([
        "dr-ashish-susvirkar",
        "dr-harish-vaja",
        "dr-mohit-modi",
        "dr-ankur-gupta",
        "dr-hiren-poojara",
        "dr-gaurav-divani",
        "dr-harsh-ajit-tilwani",
        "dr-ravi-rajani",
        "dr-tausif-surahwardi",
        "dr-jaymin-somani",
      ]),
    },
  ]
])

export type Affiliation = (typeof affiliations)["hospitals"][number]
